import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const PasswordReset = () => {
  const { token } = useParams();  // Get the token from the URL parameters
  const navigate = useNavigate(); // Hook to programmatically navigate
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submitting

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set submitting state to true

    try {
      const response = await axios.post(`https://ampcds.onrender.com/jobs/reset-password/${token}`, { newPassword });
      setMessage(response.data.message);
      // Redirect to a success page or login page
      navigate('/login'); // Adjust the redirect path as needed
    } catch (error) {
      setMessage('Error resetting password');
    } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Reset Your Password</h2>
      <form onSubmit={handleResetPassword} style={styles.form}>
        <input
          type="password"
          placeholder="Enter new password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          style={styles.input}
          required
        />
        <button type="submit" style={styles.button} disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Reset Password'}
        </button>
      </form>
      {message && <p style={styles.message}>{message}</p>}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: '#f5f5f5',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    marginTop: '90px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#333',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '400px',
  },
  input: {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '16px',
    outline: 'none',
  },
  button: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    borderRadius: '5px',
    border: 'none',
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '10px',
  },
  message: {
    marginTop: '20px',
    color: '#d9534f',
    fontSize: '14px',
  },
};

export default PasswordReset;
