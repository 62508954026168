import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome, FaUser, FaBriefcase, FaSignOutAlt, FaCreditCard, FaBuilding, FaMoneyCheckAlt, FaQuestionCircle, FaLifeRing } from 'react-icons/fa'; // Import FaLifeRing for Support icon
import '../styles/Navigation.css';

const Navigation = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;
    setUser(parsedUser);
    setIsLoggedIn(!!(parsedUser && parsedUser.accountNumber));

    const handleDocumentClick = (event) => {
      if (menuVisible && menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [menuVisible]);

  const handleSignOut = () => {
    if (window.confirm('Are you sure you want to sign out?')) {
      localStorage.clear();
      setMenuVisible(false);
      navigate('/login');
    }
  };

  return (
    <nav className="navigation-container" ref={menuRef}>
      <div className="menu-icon" onClick={() => setMenuVisible(!menuVisible)}>
        &#9776;
      </div>
      <ul className={`menu-items ${menuVisible ? 'visible' : ''}`}>
        {isLoggedIn ? (
          <>
            <div className="user-info">
              <span className="user-name">Chief, {user.fullName}</span>
            </div>
            <Link to="/" className="menu-list" onClick={() => setMenuVisible(false)}>
              <li><FaHome /> <span className="menu-text">Home</span></li>
            </Link>
            <Link to="/profile" className="menu-list" onClick={() => setMenuVisible(false)}>
              <li><FaUser /> <span className="menu-text">Profile</span></li>
            </Link>
            <Link to="/my-jobs" className="menu-list" onClick={() => setMenuVisible(false)}>
              <li><FaBriefcase /> <span className="menu-text">My Jobs</span></li>
            </Link>
            <Link to="/link-bank-account" className="menu-list" onClick={() => setMenuVisible(false)}>
              <li><FaCreditCard /> <span className="menu-text">Bank</span></li>
            </Link>

            <Link to="/faq-page" className="menu-list" onClick={() => setMenuVisible(false)}>
              <li><FaQuestionCircle /> <span className="menu-text">FAQ</span></li>
            </Link>

            {/* New Support link */}
            <Link to="/support" className="menu-list" onClick={() => setMenuVisible(false)}>
              <li><FaLifeRing /> <span className="menu-text">Support</span></li>
            </Link>

            <div className="section-separator"></div>

            {(user?.phoneNumber === '08106292993' || user?.phoneNumber === '08108149145') && (
              <Link to={`/accounting/${user._id}`} className="menu-list" onClick={() => setMenuVisible(false)}>
                <li><FaMoneyCheckAlt /> <span className="menu-text">Accounting</span></li>
              </Link>
            )}
            {(user?.phoneNumber === '08106292993' || user?.phoneNumber === '08069359653') && (
              <Link to="/public-relation" className="menu-list" onClick={() => setMenuVisible(false)}>
                <li><FaBriefcase /> <span className="menu-text">Public Relations</span></li>
              </Link>
            )}

            <div className="section-separator"></div>

            {user?.isSubscribed && (
              <Link to="/business-centre" className="menu-list" onClick={() => setMenuVisible(false)}>
                <li><FaBuilding /> <span className="menu-text">Business Centre</span></li>
              </Link>
            )}

            <li className="menu-list sign-out" onClick={handleSignOut}>
              <FaSignOutAlt /> <span className="menu-text">Sign Out</span>
            </li>
          </>
        ) : (
          <>
            <Link to="/signup" className="menu-list" onClick={() => setMenuVisible(false)}>
              <li><span className="menu-text">Create New Account</span></li>
            </Link>
            <Link to="/leadership-focus" className="menu-list" onClick={() => setMenuVisible(false)}>
              <li><span className="menu-text">Blog</span></li>
            </Link>
            <Link to="/faq-page" className="menu-list" onClick={() => setMenuVisible(false)}>
              <li><span className="menu-text">Solutions</span></li>
            </Link>

            {/* New Support link for logged-out users */}
            <Link to="/support" className="menu-list" onClick={() => setMenuVisible(false)}>
              <li><FaLifeRing /> <span className="menu-text">Support</span></li>
            </Link>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navigation;
