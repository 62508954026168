import React, { useState } from 'react';
import '../styles/BusinessCentre.css';
import axios from 'axios';

const BusinessCardForm = () => {
    const [serviceRendered, setServiceRendered] = useState('');
    const [serviceDescription, setServiceDescription] = useState('');
    const [currentLocation, setCurrentLocation] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [minUnits, setMinUnits] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isFormVisible, setIsFormVisible] = useState(true); // State to manage form visibility

    
const services = [
  'Accounting',
  'Adult Education',
  'Alarm System Installation',
  'Almajiri Care Services',
  'Alternative Medicine/Herbal Medicine',
  'Animal Husbandry',
  'Animation Services',
  'Appliance Repairs (TV, Fridge, etc.)',
  'Arbitration and Mediation',
  'Architectural Drafting',
  'Armed Escorts',
  'Arts and Craft Sales',
  'Beverage Distribution',
  'Bead Making',
  'Bee Farming and Honey Processing',
  'Birthday Party Planning',
  'Bike Dispatch Services',
  'Borehole Drilling',
  'Bouncer Services',
  'Brand Consulting',
  'Bridal Makeup and Hair',
  'Business Registration Services',
  'Calligraphy and Signwriting',
  'Cargo Shipments',
  'Career Counseling',
  'Car Rental and Leasing',
  'Car Wash Services',
  'Cassava Processing and Gari Sales',
  'CCTV Installation',
  'Civil Engineering Services',
  'Cloud Computing Services',
  'Computer Repairs',
  'Costume Rentals',
  'Courier Services',
  'Cow Skin (Ponmo) Processing',
  'Crop Disease Diagnosis',
  'Cultural Dance Performances',
  'Cybersecurity Services',
  'Data Analysis Services',
  'Digital Literacy Training',
  'Digital Marketing',
  'Document Lamination and Binding',
  'Drone Surveillance Services',
  'Drumming and Traditional Music Services',
  'eLearning Platforms',
  'E-commerce Solutions',
  'Elderly Care Services',
  'Event Hosting (MCs, DJs)',
  'Event Rentals (canopies, chairs, cooling vans)',
  'Fashion Design',
  'Feasibility Study and Market Research',
  'Fire Safety Equipment Sales',
  'Fish Farming Consultancy',
  'Fitness Boot Camps',
  'Fleet Management Services',
  'Footwear Repairs',
  'Funeral Services',
  'Furniture Making',
  'Gas Cylinder Refilling',
  'General Medical Practice',
  'Generator Repairs',
  'Home Decluttering Services',
  'Home Lesson Tutors',
  'Home Meal Delivery',
  'Home Renovation Services',
  'Hotel and Guesthouse Management',
  'HVAC Technicians (AC and Refrigerator)',
  'Irrigation System Installation',
  'IT Networking Solutions',
  'Key Duplication Services',
  'Kente and Aso Oke Weaving',
  'Legal Advocacy',
  'Life Coaching',
  'Local Beer (Palm Wine) Brewing',
  'Local Food Catering (e.g., Amala, Suya, Akara)',
  'Makeup and Hair',
  'Matchmaking Services',
  'Medical Laboratory Services',
  'Midwifery and Childbirth Services',
  'Mobile App Development',
  'Mobile Clinics',
  'NGO Management Consultancy',
  'Notary Services',
  'Occupational Therapy',
  'Okada and Tricycle (Keke) Rentals',
  'Optometry Services',
  'Organic Fertilizer Distribution',
  'Orthopedic Care',
  'Outdoor Catering',
  'Palm Oil Processing',
  'Pediatric and Nanny Services',
  'Personal Assistants on Hire',
  'Personal Shopping',
  'Personal Styling',
  'Pharmacy Services',
  'Phone Repairs',
  'Physiotherapy',
  'Poultry Farming',
  'Private Investigators',
  'Private School Ownership',
  'Public Relations (PR)',
  'Real Estate Valuation',
  'Recycling and Upcycling',
  'Ride-hailing Services (Uber, Bolt)',
  'Road Construction',
  'Roofing and Ceiling Installation',
  'SAT/TOEFL/IELTS Preparation Classes',
  'Satellite TV Installation',
  'Septic Tank Installation and Cleaning',
  'Skill Acquisition Training',
  'Small Chops Catering',
  'Snack and Pastry Making',
  'Social Media Management',
  'Software Installation and Maintenance',
  'Solar Panel Installation',
  'Spa Services',
  'Stage Lighting and Sound Setup',
  'Stamp and Seal Makers',
  'Street Food Services',
  'Tax Advisory Services',
  'Traditional Event Rentals (Talking Drums, Horses)',
  'Traditional Hair Weaving (e.g., Didi, Shuku)',
  'Traditional Herbal Healing',
  'Tractor Leasing',
  'Tribal Tattoo Artistry',
  'Travel Agency Services',
  'Upholstery Repairs',
  'Vehicle Inspection Services',
  'Vocational Institutes',
  'Waste Management Services',
  'Water Treatment and Purification',
  'Wedding Planning',
  'Watchdog Rentals',
  'Welding Services',
  'Wig Making and Revamping',
  'Yoruba, Igbo, Hausa Translation Services'
];

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setSuccessMessage('');

        const userId = JSON.parse(localStorage.getItem('user'))?._id;
        const businessCardData = {
            userId,
            serviceRendered,
            serviceDescription,
            currentLocation,
            businessName,
            minUnits,
        };

        try {
            await axios.post('https://ampcds.onrender.com/api/update-business-fields', businessCardData);
            setSuccessMessage('Business card submitted successfully!');
            // Hide form
            setIsFormVisible(false);
            // Clear inputs
            setServiceRendered('');
            setServiceDescription('');
            setCurrentLocation('');
            setBusinessName('');
        } catch (error) {
            console.error('Error submitting business card:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="business-card-form-container" style={{ marginBottom: '90px' }}>
            {isFormVisible && ( // Conditionally render the form based on isFormVisible
                <>
                    <h2 className="form-title">Submit Your Business Card</h2>
                    <form className="business-card-form" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="serviceRendered">Service Rendered</label>
                            <select
                                id="serviceRendered"
                                value={serviceRendered}
                                onChange={(e) => setServiceRendered(e.target.value)}
                                required
                                className="form-input"
                            >
                                <option value="" disabled>Select a service</option>
                                {services.map((service, index) => (
                                    <option key={index} value={service}>
                                        {service}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="serviceDescription">Service Description</label>
                            <textarea
                                id="serviceDescription"
                                value={serviceDescription}
                                onChange={(e) => setServiceDescription(e.target.value)}
                                required
                                className="form-input"
                                placeholder="Your service to the world."
                            ></textarea>
                        </div>

                        <div className="form-group">
                            <label htmlFor="currentLocation">Location</label>
                            <input
                                type="text"
                                id="currentLocation"
                                value={currentLocation}
                                onChange={(e) => setCurrentLocation(e.target.value)}
                                required
                                className="form-input"
                                placeholder="e.g., New York, NY"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="businessName">Business Name</label>
                            <input
                                type="text"
                                id="businessName"
                                value={businessName}
                                onChange={(e) => setBusinessName(e.target.value)}
                                className="form-input"
                                placeholder="Your business name"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="businessName">Set your Min service charge</label>
                            <input
                                type="text"
                                id="minUnits"
                                value={minUnits}
                                onChange={(e) => setMinUnits(e.target.value)}
                                className="form-input"
                                placeholder="Lowest amount your business may charge"
                                required
                            />
                        </div>

                        <button type="submit" className="submit-button" disabled={isSubmitting}>
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                        </button>

                        {successMessage && <p className="success-message">{successMessage}</p>}
                    </form>
                </>
            )}
        </div>
    );
};

export default BusinessCardForm;
