import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CommentsComponent from '../components/Comments';
import FormatText from '../components/FormatText';
import HiringConfidence from '../components/disclaimer';
import JobDetailsFooter from '../components/JobDetailsFooter'; // Modularized Footer
import '../styles/JobDetail.css';

// Utility functions for local storage
const getLocalStorageItem = (key) => JSON.parse(localStorage.getItem(key)) || null;
const setLocalStorageItem = (key, value) => localStorage.setItem(key, JSON.stringify(value));

// Utility function for API calls
const apiCall = async (url, method = 'GET', body = null) => {
    const options = {
        method,
        headers: { 'Content-Type': 'application/json' },
        ...(body && { body: JSON.stringify(body) }),
    };
    const response = await fetch(url, options);
    if (!response.ok) {
        throw new Error(`API error: ${response.status}`);
    }
    return response.json();
};

const ROUTES = {
    LOGIN: '/login',
    UPGRADE: '/upgrade-page',
};

const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = 1440;

const JobDetails = () => {
    const { jobId } = useParams();
    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const [showPhoneNumber, setShowPhoneNumber] = useState(false);
    const [userNeedsUpgrade, setUserNeedsUpgrade] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchJobDetails = async () => {
            setLoading(true);
            try {
                const localJobs = getLocalStorageItem('jobs') || [];
                const selectedJob = localJobs.find((job) => job._id === jobId);

                if (selectedJob) {
                    setJob(selectedJob);
                } else {
                    await fetchJobFromServer();
                }
            } catch (error) {
                console.error('Error loading job details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchJobDetails();
    }, [jobId]);

    const fetchJobFromServer = async () => {
        try {
            const jobData = await apiCall(`${process.env.REACT_APP_API_URL}/jobs/get-job/${jobId}`);
            setJob(jobData);
            saveJobToLocalStorage(jobData);
        } catch (error) {
            console.error('Error fetching job from server:', error);
            setJob(null); // Indicate an error occurred
        }
    };

    const saveJobToLocalStorage = (jobData) => {
        const localJobs = getLocalStorageItem('jobs') || [];
        if (!localJobs.some((job) => job._id === jobData._id)) {
            localJobs.push(jobData);
            setLocalStorageItem('jobs', localJobs);
        }
    };

    const handleCallButtonClick = async (phoneNumber, jobId) => {
        try {
            const storedUserData = getLocalStorageItem('user');
            if (!storedUserData) {
                navigate(ROUTES.LOGIN);
                return;
            }

            if (requiresUpgrade(storedUserData)) {
                handleUpgradeRedirect();
                return;
            }

            // Call API before initiating the phone call
            await callJobAndIncreaseCount(phoneNumber, jobId, storedUserData._id);

            // Proceed with initiating the call only if API succeeds
            window.location.href = `tel:${phoneNumber}`;
        } catch (error) {
            console.error('Error initiating call:', error);
        }
    };

    const handleUpgradeRedirect = () => {
        setUserNeedsUpgrade(true);
        setTimeout(() => {
            navigate(ROUTES.UPGRADE);
        }, 2000); // 2-second delay
    };

    const callJobAndIncreaseCount = async (phoneNumber, jobId, userId) => {
        try {
            await apiCall(`${process.env.REACT_APP_API_URL}/jobs/call-job-and-increase-count/${jobId}`, 'POST', {
                userId,
                phoneNumber,
            });
            decrementFreeJobCount();
            await updateUserData(userId);
        } catch (error) {
            console.error('Failed to update call count:', error);
        }
    };

    const decrementFreeJobCount = () => {
        const storedUserData = getLocalStorageItem('user');
        if (storedUserData && storedUserData.freeJob > 0) {
            storedUserData.freeJob--;
            setLocalStorageItem('user', storedUserData);
        }
    };

    const updateUserData = async (userId) => {
        try {
            const userData = await apiCall(`${process.env.REACT_APP_API_URL}/api/auth/user/${userId}`);
            setLocalStorageItem('user', userData);
        } catch (error) {
            console.error('Error updating user data:', error);
        }
    };

    const requiresUpgrade = (userData) => {
        return userData.freeJob === 0 && !userData.isSubscribed;
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'Date not available';
        const date = new Date(dateString);
        const now = new Date();
        const diffInMinutes = Math.floor((now - date) / 60000);

        if (isNaN(diffInMinutes)) return 'Invalid date';
        if (diffInMinutes < MINUTES_IN_HOUR) {
            return `${diffInMinutes} mins ago`;
        } else if (diffInMinutes < MINUTES_IN_DAY) {
            return `${Math.floor(diffInMinutes / MINUTES_IN_HOUR)} hrs ago`;
        } else {
            return `${Math.floor(diffInMinutes / MINUTES_IN_DAY)} days ago`;
        }
    };

    if (loading) {
        return <div className="loading">Loading job details...</div>;
    }

    if (!job) {
        return <div className="error-message">Unable to load job details. Please try again later.</div>;
    }

    return (
        <div className="job-details-container">
            <div className="job-details-card">
                <h3 className="job-creator">{job.creator}</h3>
                <div className="job-location">
                    <p>{job.location}</p>
                </div>
                <p className="job-date">{job.date ? formatDate(job.date) : 'Date not available'}</p>
                <p className="job-description">
                    <FormatText text={job.description} />
                </p>

                <JobDetailsFooter job={job} />

                <HiringConfidence />

                <div className="job-actions">
                    {/* Comments Button */}
                    <div className="action-item">
                        <button
                            className="comments-button"
                            onClick={() => setShowComments(!showComments)}
                        >
                            Comments
                        </button>
                    </div>

                    {/* Call Button and Phone Number Popup */}
                    <div className="action-item">
                        {showPhoneNumber ? (
                            <div className="phone-number-display">
                                <p
                                    className="phone-number-text"
                                    onClick={() => handleCallButtonClick(job.phoneNumber, jobId)}
                                >
                                    {job.phoneNumber}
                                </p>
                                <button
                                    className="phone-number-hide-button"
                                    onClick={() => setShowPhoneNumber(false)}
                                >
                                    Hide
                                </button>
                            </div>
                        ) : (
                            <button
                                className="call-button"
                                onClick={() => setShowPhoneNumber(true)}
                            >
                                Call
                            </button>
                        )}
                    </div>

                    {/* Upgrade Message */}
                    {userNeedsUpgrade && (
                        <p className="upgrade-message">
                            Please upgrade your account to make calls. Redirecting...
                        </p>
                    )}

                    {/* Comments Section */}
                    {showComments && <CommentsComponent />}
                </div>
            </div>
        </div>
    );
};

export default JobDetails;
