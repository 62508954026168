import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UpgradeToUnlimitedCalls = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const user = localStorage.getItem('user');
        if (!user) {
            // Redirect to login if user data is not found, and pass the current path to redirect back after login
            const currentUrl = window.location.pathname;
            navigate(`/login?redirectUrl=${encodeURIComponent(currentUrl)}`);
        }
    }, [navigate]);

    const handleUpgrade = () => {
        const user = localStorage.getItem('user');
        if (!user) {
            // If no user, do not proceed with upgrade logic
            return;
        }

        // Continue with upgrade process
        console.log('Upgrade logic goes here');
    };

    const user = localStorage.getItem('user');
    let isSubscribed = false;
    if (user) {
        const userData = JSON.parse(user);
        if (userData.isSubscribed && !userData.teamKey) {
            isSubscribed = true;
        }
    }

    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <div>
                    <h2 style={styles.title}>For you</h2>
                    <p style={styles.subtitle}>
                        Students, artisan, and workman looking for jobs.
                    </p>
                </div>
                <p style={styles.price}>₦2,365/once</p>
            </div>
            <div style={styles.features}>
                <ul>
                    <li>2+ Job SMS daily. 60 Max. </li>
                    <li>Direct access to clients.</li>
                    <li>Clients pays you directly</li>
                    <li>Placement on the home page for global visibility</li>
                    <li>Render one service under this plan</li>
                    <li>No extra fees</li>
                </ul>
                {isSubscribed ? (
                    <p style={styles.subscribedText}>
                        You're subscribed! You’re set to receive job alerts and unlimited calls this month. Don’t forget, if you don’t land a job in your first month, you’ll roll over to the next month and so on—on us.
                    </p>
                ) : (
                    <a 
                        href="https://flutterwave.com/pay/cgku4fgzrdyc" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={styles.link} 
                        onClick={handleUpgrade}
                    >
                        Get jobs Now! 100% money back guarantee.
                    </a>
                )}
            </div>
            <p style={styles.footerText}>
               Access global clients today.
            </p>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'left',
        margin: '50px auto',
        width: '80%',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: '20px',
    },
    title: {
        fontSize: '24px',
        color: '#333',
        marginRight: '10px',
    },
    subtitle: {
        fontSize: '16px',
        color: '#555',
        marginBottom: '10px',
    },
    price: {
        fontSize: '20px',
        color: '#333',
    },
    features: {
        backgroundColor: '#f4f4f4',
        borderRadius: '8px',
        padding: '20px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    link: {
        display: 'block',
        fontSize: '16px',
        color: '#007bff',
        textDecoration: 'none',
        marginTop: '20px',
    },
    subscribedText: {
        fontSize: '16px',
        color: '#333',
        marginTop: '20px',
        backgroundColor: '#e1f5fe',
        padding: '10px',
        borderRadius: '4px',
    },
    footerText: {
        fontSize: '14px',
        color: '#555',
        marginTop: '30px',
    },
};

export default UpgradeToUnlimitedCalls;
