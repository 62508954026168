import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Fuse from 'fuse.js'; // Import Fuse for fuzzy search
import { Link } from 'react-router-dom';
import '../styles/AccountUpgrade.css';

const AccountUpgrade = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://ampcds.onrender.com/api/auth/subscribed-users');
        localStorage.setItem('unsub-users', JSON.stringify(response.data.users));
        setUsers(response.data.users);
        setFilteredUsers(response.data.users); // Initialize filteredUsers
      } catch (error) {
        console.error('Error fetching users:', error);
        // Handle error fetching users
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredUsers(users);
    } else {
      // Perform fuzzy search based on searchTerm
      const fuse = new Fuse(users, {
        keys: ['fullName', 'phoneNumber'],
        includeScore: true,
        threshold: 0.4,
      });
      const result = fuse.search(searchTerm);
      setFilteredUsers(result.map(item => item.item));
    }
  }, [searchTerm, users]);

  const handleUpgrade = async (userId) => {
    try {
      await axios.post('https://ampcds.onrender.com/api/auth/update-subscription', { userId });
      alert('User subscription updated successfully!');
    } catch (error) {
      console.error('Error updating subscription:', error);
      alert('Failed to update user subscription. Please try again.');
    }
  };

  return (
    <div className="account-upgrade-container" style={{ marginTop: '90px' }}>
      <div className="header">
        <Link to="/" className="back-link">
          &#8592; Back to Home
        </Link>
      </div>
      <h2>Upgrade User Account</h2>

      {/* Display total number of users */}
      <div className="total-count" style={{ marginBottom: '20px', fontWeight: 'bold' }}>
        Total Users: {filteredUsers.length}
      </div>

      <input
        type="text"
        placeholder="Search users..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ margin: '50px auto', width: '93%', textAlign: 'left' }}
      />
      <div className="user-list">
        {filteredUsers.map(user => (
          <div key={user._id} className="user-item">
            <p><strong>Full Name:</strong> {user.fullName}</p>
            <p><strong>Phone Number:</strong> {user.phoneNumber}</p>
            <button className="upgrade-button" onClick={() => handleUpgrade(user._id)}>
              Upgrade User
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountUpgrade;
