import React, { useState } from 'react';
import { FaMapMarkerAlt, FaCheckCircle, FaRegCircle, FaUserCircle } from 'react-icons/fa';

const JobDescription = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      <p
        style={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: isExpanded ? 'unset' : 2,
          cursor: 'pointer',
          color: '#444',
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {description}
      </p>
      {description.length > 100 && (
        <span
          style={{ color: '#1d72b8', cursor: 'pointer', fontSize: '14px' }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? 'Show less' : 'Read more'}
        </span>
      )}
    </div>
  );
};

const JobCard = ({ job, handleViewButtonClick, handleCallJob, formatCurrency }) => {
  const [isCalled, setIsCalled] = useState(false);

  const handleCallJobClick = async (e) => {
    e.stopPropagation();
    const success = await handleCallJob(job._id);
    if (success) {
      setIsCalled(true);
    }
  };

  return (
    <div
      onClick={() => handleViewButtonClick(job._id)}
      style={{
        backgroundColor: '#f4f4f4',
        borderRadius: '12px',
        padding: '20px',
        border: '1px solid #ddd',
        marginBottom: '15px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
        transition: 'box-shadow 0.3s ease, transform 0.3s ease',
      }}
      onMouseEnter={(e) => (e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.2)')}
      onMouseLeave={(e) => (e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)')}
    >
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        {job.profileUrl ? (
          <img
            src={job.profileUrl}
            alt={`${job.creator}'s profile`}
            style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
          />
        ) : (
          <FaUserCircle style={{ fontSize: '40px', color: '#666', marginRight: '10px' }} />
        )}
        <div>
          <h3 style={{ color: '#333', margin: 0 }}>{job.creator}</h3>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaMapMarkerAlt style={{ fontSize: '16px', color: '#666', marginRight: '5px' }} />
            <p style={{ fontSize: '14px', color: '#666', margin: 0 }}>{job.location}</p>
          </div>
        </div>
      </div>
      <JobDescription description={job.description} />
      {job.mediaUrl && (
        <div>
          {job.mediaUrl.match(/\.(mp4|webm|ogg)$/i) ? (
            <video controls style={{ maxWidth: '100%', borderRadius: '8px' }}>
              <source src={job.mediaUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              src={job.mediaUrl}
              alt="Job media"
              style={{ maxWidth: '100%', borderRadius: '8px' }}
              onError={(e) => (e.target.src = 'fallback-image-url.jpg')}
            />
          )}
        </div>
      )}
      <div
        style={{
          backgroundColor: '#f8f8f8',
          border: '1px solid #ddd',
          width: '100%',
          padding: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '8px',
        }}
      >
        {job.maxBudget > 1 && (
          <p style={{ fontSize: '16px', color: '#333' }}>{formatCurrency(job.maxBudget)}</p>
        )}
        <p style={{ fontSize: '14px', color: '#666' }}>
          {isCalled ? (
            <FaCheckCircle style={{ fontSize: '16px', color: '#1d72b8' }} />
          ) : (
            <FaRegCircle
              style={{ fontSize: '16px', color: '#1d72b8', cursor: 'pointer' }}
              onClick={handleCallJobClick}
            />
          )}
          {isCalled ? 'Called' : job.callCount}
        </p>
      </div>
    </div>
  );
};

export default JobCard;
