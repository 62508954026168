import React, { useState } from 'react';
import axios from 'axios';

const TextForm = () => {
  const [textInput, setTextInput] = useState('');
  const [destination, setDestination] = useState('');
  const [serverResponse, setServerResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [mediaUrl, setMediaUrl] = useState('');
  const [uploading, setUploading] = useState(false);
  const [faqQuestion, setFaqQuestion] = useState('');
  const [faqAnswer, setFaqAnswer] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [jobLocation, setJobLocation] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [minBudget, setMinBudget] = useState('');
  const [maxBudget, setMaxBudget] = useState('');

  // Array of alternate fullName options for jobs
  const jobFullNameOptions = [
    'Community Jobs',
    'Global Opportunities',
    'Top Talent',
    'Your Dream Job',
    'Elite Connections',
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const storedUser = localStorage.getItem('user');
      if (!storedUser) {
        console.error('User data not found in local storage');
        return;
      }

      const { _id: userId } = JSON.parse(storedUser);
      let endpoint = '';
      let data = {};

      if (destination === 'blog') {
        endpoint = 'https://ampcds.onrender.com/jobs/blogs';
        data = { title, body: textInput, picture: mediaUrl };
      } else if (destination === 'sms') {
        endpoint = 'https://ampcds.onrender.com/jobs/send-sms';
        data = { body: textInput };
      } else if (destination === 'subscribed') {
        endpoint = 'https://ampcds.onrender.com/jobs/send-sms-sub-users';
        data = { body: textInput };
      } else if (destination === 'faqs') {
        endpoint = 'https://ampcds.onrender.com/api/faqs';
        data = { question: faqQuestion, answer: faqAnswer };
      } else if (destination === 'jobs') {
        // Validation for required fields
        if (!jobDescription || !jobLocation || !phoneNumber) {
          alert('Please fill in all required fields (Description, Location, Phone Number).');
          setIsLoading(false);
          return;
        }

        // Randomly select a fullName
        const randomFullName = jobFullNameOptions[Math.floor(Math.random() * jobFullNameOptions.length)];

        endpoint = 'https://ampcds.onrender.com/jobs/post-job';
        data = {
          userId,
          fullName: randomFullName,
          description: jobDescription,
          location: jobLocation,
          phoneNumber,
          minBudget,
          maxBudget,
          mediaUrl,
          creatorId: userId,
        };
      }

      const response = await axios.post(endpoint, data);
      setServerResponse(response.data);
      setTextInput('');
      setTitle('');
      setMediaUrl('');
      setFaqQuestion('');
      setFaqAnswer('');
      setJobDescription('');
      setJobLocation('');
      setPhoneNumber('');
      setMinBudget('');
      setMaxBudget('');
    } catch (error) {
      console.error('Error submitting text:', error);
      setServerResponse({ error: 'Failed to submit' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleMediaUpload = (event) => {
    setUploading(true);
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setMediaUrl(reader.result);
      setUploading(false);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const renderBlogFields = () => {
    if (destination === 'blog') {
      return (
        <div style={{ width: '100%' }}>
          <input
            type="text"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            placeholder="Enter title..."
            required
          />
          <input
            type="file"
            accept="image/*,video/*"
            onChange={handleMediaUpload}
            required
          />
          {uploading ? <p>Uploading media...</p> : mediaUrl && <p>Media uploaded successfully!</p>}
        </div>
      );
    }
    return null;
  };

  const renderFaqFields = () => {
    if (destination === 'faqs') {
      return (
        <div style={{ width: '100%' }}>
          <input
            type="text"
            value={faqQuestion}
            onChange={(event) => setFaqQuestion(event.target.value)}
            placeholder="Enter FAQ question..."
            required
          />
          <textarea
            value={faqAnswer}
            onChange={(event) => setFaqAnswer(event.target.value)}
            placeholder="Enter FAQ answer..."
            required
            style={{ width: '100%', borderRadius: '8px', height: '60px' }}
          />
        </div>
      );
    }
    return null;
  };

  const renderJobFields = () => {
    if (destination === 'jobs') {
      return (
        <div style={{ width: '100%' }}>
          <textarea
            value={jobDescription}
            onChange={(event) => setJobDescription(event.target.value)}
            placeholder="Job Description (Required)"
            required
            style={{ width: '100%', borderRadius: '8px', height: '60px' }}
          />
          <input
            type="text"
            value={jobLocation}
            onChange={(event) => setJobLocation(event.target.value)}
            placeholder="Location (Required)"
            required
          />
          <input
            type="text"
            value={phoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)}
            placeholder="Phone Number (Required)"
            required
          />
          <input
            type="text"
            value={minBudget}
            onChange={(event) => setMinBudget(event.target.value)}
            placeholder="Minimum Budget"
          />
          <input
            type="text"
            value={maxBudget}
            onChange={(event) => setMaxBudget(event.target.value)}
            placeholder="Maximum Budget"
          />
          <input
            type="file"
            accept="image/*,video/*"
            onChange={handleMediaUpload}
          />
          {uploading ? <p>Uploading media...</p> : mediaUrl && <p>Media uploaded successfully!</p>}
        </div>
      );
    }
    return null;
  };

  const renderTextInput = () => {
    if (destination !== 'faqs' && destination !== 'jobs') {
      return (
        <textarea
          value={textInput}
          onChange={(event) => setTextInput(event.target.value)}
          placeholder="Message Body"
          required
          style={{ width: '100%', borderRadius: '8px', height: '60px' }}
        />
      );
    }
    return null;
  };

  const renderServerResponse = () => {
    if (serverResponse) {
      if (typeof serverResponse === 'string') {
        return <p>{serverResponse}</p>;
      } else if (typeof serverResponse === 'object') {
        return (
          <div>
            {Object.keys(serverResponse).map((key) => (
              <p key={key}><strong>{key}:</strong> {serverResponse[key]}</p>
            ))}
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div style={{ width: '90%', textAlign: 'left', marginLeft: '10px', marginTop: '90px' }}>
      <h1 style={{ textAlign: 'center' }}>Create New PR</h1>
      <form onSubmit={handleSubmit}>
        <select
          value={destination}
          onChange={(event) => setDestination(event.target.value)}
          required
          style={{ marginBottom: '10px' }}
        >
          <option value="">Send To:</option>
          <option value="blog">Blog</option>
          <option value="sms">SMS</option>
          <option value="subscribed">Subscribed Users</option>
          <option value="faqs">FAQs</option>
          <option value="jobs">Jobs</option>
        </select>
        {renderBlogFields()}
        {renderFaqFields()}
        {renderJobFields()}
        {renderTextInput()}
        <button type="submit" disabled={isLoading || uploading} style={{ backgroundColor: 'green' }}>
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>
      </form>
      {renderServerResponse()}
    </div>
  );
};

export default TextForm;
