import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import JobList from '../components/JobList';
import ServiceRenderedJobsComponent from '../components/ServiceRenderedJobsComponent';
import { FaPlusCircle } from 'react-icons/fa';
import FloatingComponent from '../components/PrivateName';
import ServicemenList from '../components/ServicemenList';
import Robin from '../components/Robin';

const JobListPage = ({ user }) => {
  const [activeComponent, setActiveComponent] = useState('servicemen');
  const [serviceRendered, setServiceRendered] = useState(false);
  const [jobCount, setJobCount] = useState(0); // State for job count
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve the last active component from localStorage
    const savedActiveComponent = localStorage.getItem('activeComponent');
    if (savedActiveComponent) {
      setActiveComponent(savedActiveComponent);
    }

    // Retrieve serviceRendered state from localStorage
    const serviceRenderedValue = localStorage.getItem('serviceRendered');
    if (serviceRenderedValue) {
      setServiceRendered(JSON.parse(serviceRenderedValue));
    }

    // Retrieve and count jobs from localStorage
    const jobs = JSON.parse(localStorage.getItem('jobs')) || [];
    setJobCount(jobs.length);
  }, []);

  const handleClick = (componentName) => {
    setActiveComponent(componentName);
    localStorage.setItem('activeComponent', componentName); // Save the selected component to localStorage
  };

  const handlePlusIconClick = () => {
    const userKey = localStorage.getItem('user');
    if (userKey) {
      navigate('/post-job');
    } else {
      navigate('/login');
    }
  };

  return (
    <div style={{
      background: 'linear-gradient(to bottom right, #e0f7fa, #ffffff)',
      minHeight: '100vh',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <div style={{
        marginTop: '110px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: '1200px',
        color: 'gray',
        marginBottom: '20px',
      }}>
        <div
          onClick={() => handleClick('servicemen')}
          style={{
            cursor: 'pointer',
            color: activeComponent === 'servicemen' ? '#1d72b8' : 'gray',
            fontWeight: activeComponent === 'servicemen' ? 'bold' : 'normal',
            textDecoration: activeComponent === 'servicemen' ? 'underline' : 'none',
          }}
        >
          Servicemen
        </div>
        <div
          onClick={() => handleClick('jobList')}
          style={{
            cursor: 'pointer',
            color: activeComponent === 'jobList' ? '#1d72b8' : 'gray',
            fontWeight: activeComponent === 'jobList' ? 'bold' : 'normal',
            textDecoration: activeComponent === 'jobList' ? 'underline' : 'none',
          }}
        >
          Classic Jobs {jobCount > 0 && `(${jobCount})`}
        </div>
        {user && serviceRendered && (
          <div
            onClick={() => handleClick('serviceRenderedJobs')}
            style={{
              cursor: 'pointer',
              color: activeComponent === 'serviceRenderedJobs' ? '#1d72b8' : 'gray',
              fontWeight: activeComponent === 'serviceRenderedJobs' ? 'bold' : 'normal',
              textDecoration: activeComponent === 'serviceRenderedJobs' ? 'underline' : 'none',
            }}
          >
            ServiceRendered-Jobs
          </div>
        )}
      </div>

      <FaPlusCircle
        style={{
          position: 'fixed',
          right: '20px',
          bottom: '20px',
          fontSize: '50px',
          color: '#1d72b8',
          cursor: 'pointer',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          borderRadius: '50%',
          transition: 'transform 0.2s ease-in-out',
        }}
        onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
        onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
        onClick={handlePlusIconClick}
      />

      <div style={{
        width: '100%',
        maxWidth: '1200px',
        marginTop: '30px',
      }}>
        {activeComponent === 'servicemen' && <ServicemenList />}
        {activeComponent === 'jobList' && <JobList />}
        {activeComponent === 'serviceRenderedJobs' && user && serviceRendered && <ServiceRenderedJobsComponent />}
        {activeComponent === 'Robin' && <Robin />}
      </div>

      <FloatingComponent />
    </div>
  );
};

export default JobListPage;
