import React, { useEffect, useState } from 'react';
import axios from 'axios';

const JobsOverview = () => {
  const [jobStats, setJobStats] = useState({
    totalJobs: 0,
    completedJobs: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get('https://ampcds.onrender.com/jobs/get-job');
        const jobs = response.data || [];

        // Calculate job stats
        const totalJobs = jobs.length;
        const completedJobs = jobs.filter((job) => job.taken === true).length;

        setJobStats({ totalJobs, completedJobs });
        setLoading(false);
      } catch (err) {
        setError('Failed to load job data.');
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  if (loading) {
    return (
      <div style={styles.container}>
        <p style={styles.text}>Loading job stats...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div style={styles.container}>
        <p style={styles.errorText}>{error}</p>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <p style={styles.text}>
        Over <span style={styles.highlight}>{jobStats.totalJobs}</span> jobs currently listed, with about{' '}
        <span style={styles.highlight}>{jobStats.completedJobs}</span> successfully completed. Excellence is everywhere.
      </p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
  },
  text: {
    fontSize: '18px',
    color: '#333',
    textAlign: 'center',
    lineHeight: '1.6',
  },
  highlight: {
    fontWeight: 'bold',
    color: '#007aff',
  },
  errorText: {
    fontSize: '16px',
    color: '#d9534f',
  },
};

export default JobsOverview;
