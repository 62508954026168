import React from "react";

const ContactNumbers = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#f7f7f7",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    fontFamily: "'Arial', sans-serif",
    color: "#333",
  };

  const headingStyle = {
    fontSize: "1.2em",
    fontWeight: "bold",
    marginBottom: "10px",
  };

  const linkStyle = {
    fontSize: "1em",
    color: "#007bff",
    textDecoration: "none",
    margin: "5px 0",
  };

  return (
    <div style={containerStyle}>
      <p style={headingStyle}>For direct global support, call/whatsapp (24/7):</p>
      <a href="tel:070711725992" style={linkStyle}>+234-711-725-992</a>
    </div>
  );
};

export default ContactNumbers;
