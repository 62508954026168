import React from 'react';
import IrunorPersuasion from '../components/IrunorPersuasion'; // Adjust the import path as needed
import '../styles/ServicemenPage.css'; // Optional CSS for additional styling specific to the servicemen page

const ServicemenPage = () => {
  return (
    <div className="servicemen-page">
      <h1 className="page-title">Welcome to Irunor's Servicemen Center</h1>
      <p className="intro-text">
        Discover how you can start earning with your skills or find life-changing job opportunities 
        through our platform. Let us help you achieve the financial freedom you deserve!
      </p>

      {/* Render the persuasion component */}
      <IrunorPersuasion />

      {/* Additional content for the ServicemenPage */}
      <section className="extra-content">
        <h2>Why Choose Irunor?</h2>
        <p>
          At Irunor, we value your talent and hard work. Our platform ensures that you are connected to 
          the best opportunities tailored to your skills. Join thousands of Nigerians already making a difference 
          with us!
        </p>
      </section>
    </div>
  );
};

export default ServicemenPage;
