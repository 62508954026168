import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactTyped } from 'react-typed'; // Install via: npm install react-typed
import '../styles/Marketing.css';

const Marketing = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger fade-in animation
    setTimeout(() => {
      setIsVisible(true);
    }, 100); // Delay to make animation noticeable
  }, []);

  const handleLearnMore = () => {
    navigate('/servicemen-page'); // Replace with actual route
  };

  const handleGetStarted = () => {
    navigate('/upgrade-page'); // Replace with actual route
  };

  return (
    <div className={`marketing-container ${isVisible ? 'fade-in' : ''}`}>
      <div className="marketing-content">
        <h1 className="headline">
          <ReactTyped
            strings={[
              'Your Next Opportunity',
              'Jobs',
              'New Jobs',
              'More Jobs',
              'Get Hired - By the World'
            ]}
            typeSpeed={80}
            backSpeed={30}
            backDelay={1000}
            startDelay={500}

            loop
            style={{ color: 'white' }}
            onStringTyped={(index, self) => {
              // Dynamically update color after each string is typed
              const colors = ['#9face6', '#74ebd5', '#1c3d59', '#f26c6c'];
              const color = colors[index % colors.length];
              self.el.style.color = color;
            }}
          />
        </h1>
        
        <div className="action-buttons">
          <button className="get-started-btn" onClick={handleGetStarted}>
            Sign up
          </button>
          <button className="learn-more-btn" onClick={handleLearnMore}>
            Learn More
          </button>
          
        </div>
      </div>
    </div>
  );
};

export default Marketing;
