import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/IrunorPersuasion.css';

const IrunorPersuasion = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/upgrade-page'); // Navigate to upgrade page
  };

  const handleLearnMore = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top smoothly
    navigate('/servicemen-page'); // Navigate to servicemen information page
  };

  return (
    <div className="persuasion-container">
      <div className="hero-section">
        <h1 className="headline">Transform Your Life Today with Irunor</h1>
        <p className="subheadline">
          Are you ready to turn your skills into income or find the job of your dreams? 
          For just <span className="price">₦2,365</span>, your journey to financial independence begins!
        </p>
      </div>

      <div className="content-section">
        <h2 className="highlight">Why Join Irunor?</h2>
        <p>
          💡 <strong>Opportunities Everywhere:</strong> With thousands of job openings and a growing network of clients,
          Irunor connects you to the biggest and best opportunities Nigeria has to offer.
        </p>
        <p>
          🚀 <strong>Fast-Track Your Income:</strong> No more waiting for “connections” or luck. Take control of your future by signing up to become a serviceman. 
          Imagine earning a steady income doing what you love or landing that high-paying job – it's now within reach.
        </p>
        <p>
          🔒 <strong>Trust and Security:</strong> At Irunor, we’ve built a platform that guarantees your payments are secure, your gigs are protected, 
          and you’re working with trusted clients and employers. Your hustle deserves nothing less!
        </p>

        <h2 className="highlight">This Is Your Moment!</h2>
        <p>
          Nigeria is brimming with talent like yours, but talent alone isn’t enough. It’s about who takes action first. 
          Don't let this chance slip through your fingers – for less than the price of a night out, you can invest in a lifetime of opportunities.
        </p>
        <p>
          What’s ₦2,365 when you think about the **endless earning potential** ahead? 
          A single job could multiply that fee a hundred times over. Success isn’t for the hesitant – it’s for those who take the leap.
        </p>

        <h2 className="highlight">Hear From Our Community:</h2>
        <blockquote className="testimonial">
          “Irunor changed my life. In just two months, I got consistent jobs and earned more than ₦50,000. 
          Best ₦2,365 I ever spent!” – Chidi, Lagos.
        </blockquote>
        <blockquote className="testimonial">
          “Becoming a serviceman on Irunor was the smartest decision of my life. 
          No more waiting for opportunities – they come straight to me.” – Amaka, Abuja.
        </blockquote>

        <blockquote className="testimonial">
          "There are many days where i'd just be sitting around, scrolling on my phone, then boom! a text message from irunor of a hot job which i later end up sharing with my family and friends. so many categories" - Daniel, Warri
          </blockquote>

        <h2 className="highlight">Limited Offer!</h2>
        <p className="urgency">
          The clock is ticking! This unbeatable fee of ₦2,365 won’t last forever. 
          Join today and get exclusive access to premium job postings, client connections, and tools that fast-track your success.
        </p>

        <div className="cta-buttons">
          <button className="cta-button primary" onClick={handleGetStarted}>
            Get Started Now
          </button>
          <button className="cta-button secondary" onClick={handleLearnMore}>
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default IrunorPersuasion;
