import React from 'react';
import HomeComponent from '../components/HomeComponent'; // Assuming HomeComponent is correctly located
import JobsOverview from '../components/JobsOverview'; // Import the JobsOverview component
import Marketing from '../components/Marketing';

const Home = () => {
  return (
    <div>
      {/* Main Home Component */}
      <HomeComponent />

      {/* Jobs Overview Section */}
      <div style={{ margin: '50px auto', width: '80%', textAlign: 'left', marginTop: '10px' }}>
        <JobsOverview />
      </div>
      <Marketing />

    </div>
  );
};

export default Home;
