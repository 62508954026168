import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import FreeJob from '../components/FreeJob';
import JobCard from '../cards/JobCard';
import styles from '../styles/JobList.css';

const JobList = () => {
  const [jobs, setJobs] = useState([]);
  const [newJobs, setNewJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const jobRefs = useRef({});

  useEffect(() => {
    const localJobs = JSON.parse(localStorage.getItem('jobs')) || [];
    const filteredLocalJobs = localJobs.filter((job) => !job.taken);
    setJobs(filteredLocalJobs);
    fetchJobs();

    const lastJobId = localStorage.getItem('lastClickedJob');
    if (lastJobId) {
      const scrollToJob = () => {
        const jobElement = jobRefs.current && jobRefs.current[lastJobId];
        if (jobElement) {
          jobElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          localStorage.removeItem('lastClickedJob');
        }
      };

      const observer = new MutationObserver(() => {
        const jobElement = jobRefs.current && jobRefs.current[lastJobId];
        if (jobElement) {
          scrollToJob();
          observer.disconnect();
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });

      setTimeout(scrollToJob, 500);
    }
  }, []);

  const fetchJobs = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://ampcds.onrender.com/jobs/get-job');
      if (!response.ok) {
        throw new Error('Failed to fetch jobs');
      }
      const data = await response.json();
      const filteredJobs = data.filter((job) => !job.taken);
      setNewJobs(filteredJobs);
      setJobs((prevJobs) => [...prevJobs, ...filteredJobs]);
      localStorage.setItem('jobs', JSON.stringify(filteredJobs));
    } catch (error) {
      console.error('Error fetching new job listings:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewButtonClick = (jobId) => {
    localStorage.setItem('lastClickedJob', jobId);
    navigate(`/job-details/${jobId}`);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(amount);
  };

  const handleCallJob = async (jobId) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) {
      alert('Please log in to apply for jobs.');
      return;
    }

    const { _id: userId, phoneNumber } = user;
    try {
      const response = await fetch(
        `https://ampcds.onrender.com/jobs/call-job-and-increase-count/${jobId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId, phoneNumber }),
        }
      );

      if (response.ok) {
        alert('Application sent successfully!');
        return true;
      } else {
        throw new Error('Failed to send application');
      }
    } catch (error) {
      console.error('Error sending application:', error);
      alert('Something went wrong. Please try again later.');
      return false;
    }
  };

  return (
    <div className={styles.container}>
      <FreeJob />

      <section className={styles.jobSection}>
        {jobs.map((job) => (
          <div
            key={job._id}
            ref={(el) => {
              if (el) jobRefs.current[job._id] = el;
            }}
          >
            <JobCard
              job={job}
              handleViewButtonClick={handleViewButtonClick}
              handleCallJob={handleCallJob}
              formatCurrency={formatCurrency}
            />
          </div>
        ))}
      </section>

      {isLoading && (
        <div className={styles.loader}>
          <div className={styles.spinner}></div>
          <p>Fetching the latest opportunities for you...</p>
        </div>
      )}

      <section className={styles.newJobSection}>
        <h2>New Jobs</h2>
        {newJobs.map((job) => (
          <div
            key={job._id}
            ref={(el) => {
              if (el) jobRefs.current[job._id] = el;
            }}
          >
            <JobCard
              job={job}
              handleViewButtonClick={handleViewButtonClick}
              handleCallJob={handleCallJob}
              formatCurrency={formatCurrency}
            />
          </div>
        ))}
      </section>
    </div>
  );
};

export default JobList;
