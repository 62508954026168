import React from 'react';
import UpgradeToUnlimitedCalls from '../components/Subs'; // Import the AboutUs component
import ContactNumbers from '../components/ContactNumbers';

const UpgradePage = () => {


  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2 style={{ margin: '20px 0' }}>
        Life makes more sense when you don't find jobs!
      </h2>
      <p style={{ marginBottom: '40px' }}>
        We understand that jobs mean money, we send jobs via SMS.
      </p>
<div style={{ marginTop: '40px', textAlign: 'center' }}>
        <UpgradeToUnlimitedCalls /> {/* Render the AboutUs component */}
        <ContactNumbers />
      </div>
      
      
    </div>
  );
};

export default UpgradePage;
